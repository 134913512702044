<template>
  <div id="page-user-view">
    <vs-alert
      color="danger"
      title="Customer Not Found"
      :active.sync="customer_not_found"
    >
      <span
        >Customer record with id: {{ $route.params.userId }} not found.
      </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'customers' }" class="text-inherit underline"
          >All Customers</router-link
        >
      </span>
    </vs-alert>

    <div id="user-data" v-if="data">
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <vx-card class="mb-base">
            <!-- Avatar -->
            <div class="vx-row">
              <!-- Information - Col 1 -->
              <div class="vx-col flex-1" id="account-info-col-1">
                <table>
                  <tr>
                    <td class="font-semibold">Name</td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Phone</td>
                    <td>
                      <a :href="`tel:${data.phone}`">{{ data.phone }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Address</td>
                    <td>{{ data.address }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">City</td>
                    <td>{{ data.city }}</td>
                  </tr>

                  <tr>
                    <td class="font-semibold">Date Created</td>
                    <td>{{ parseDate(data.date_created) }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Date Updated</td>
                    <td>{{ parseDate(data.date_updated) }}</td>
                  </tr>
                </table>
              </div>
              <!-- /Information - Col 1 -->

              <!-- Information - Col 2 -->
              <!-- <div class="vx-col flex-1" id="account-info-col-2">
                <table>
                <tr>
                    <td class="font-semibold">Status</td>
                    <td>{{ data.status }}</td>
                </tr>
                <tr>
                    <td class="font-semibold">Role</td>
                    <td>{{ data.role }}</td>
                </tr>
                <tr>
                    <td class="font-semibold">Company</td>
                    <td>{{ data.company }}</td>
                </tr>
                </table>
            </div> -->
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full lg:w-1/2">
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/2">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CheckCircleIcon"
                icon-right
                :statistic="totalSales"
                statisticTitle="Total Purchases"
                color="success"
              />
            </div>
            <div class="vx-col w-full lg:w-1/2">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CreditCardIcon"
                icon-right
                :statistic="outstandingBalance"
                statisticTitle="Outstanding Balance"
                color="warning"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="data-list-list-view" class="data-list-container mb-base">
        <vs-table
          ref="table"
          v-model="selected"
          pagination
          :max-items="itemsPerPage"
          search
          :data="sales"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
            <div
              class="flex flex-wrap-reverse items-center data-list-btn-container"
            >
              <!-- ACTION - DROPDOWN -->
              <!-- <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer mr-4 mb-4"
                >
                    <div
                    class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                    >
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>

                    <vs-dropdown-menu>
                    <vs-dropdown-item>
                        <span class="flex items-center">
                        <feather-icon
                            icon="TrashIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                        />
                        <span>Delete</span>
                        </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                        <span class="flex items-center">
                        <feather-icon
                            icon="ArchiveIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                        />
                        <span>Archive</span>
                        </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                        <span class="flex items-center">
                        <feather-icon
                            icon="FileIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                        />
                        <span>Print</span>
                        </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                        <span class="flex items-center">
                        <feather-icon
                            icon="SaveIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                        />
                        <span>Another Action</span>
                        </span>
                    </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown> -->

              <!-- ADD NEW -->
              <div
                class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center font-medium text-base text-primary border border-solid border-primary"
                @click="addNewData"
              >
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">Add New</span>
              </div>
            </div>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
              vs-trigger-click
              class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                  {{
                    sales.length - currentPage * itemsPerPage > 0
                      ? currentPage * itemsPerPage
                      : sales.length
                  }}
                  of {{ queriedItems }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <template slot="thead">
            <vs-th sort-key="date">Date</vs-th>
            <vs-th sort-key="branch_name">Branch</vs-th>
            <vs-th sort-key="payment_method">Payment Method</vs-th>
            <vs-th sort-key="amount">Amount</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in sales">
                <vs-td>
                  <p class="sale-phone font-medium truncate">
                    {{ parseDate(tr.date_created) }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="sale-id font-medium truncate">
                    {{ tr.branch.branch_name }}
                  </p>
                </vs-td>

                <vs-td>
                  <p
                    class="sale-id font-medium truncate"
                    :style="[
                      tr.credit ? { color: 'red', fontWeight: '800' } : {},
                    ]"
                  >
                    {{
                      tr.credit
                        ? 'UNPAID'
                        : tr.payment
                          ? tr.payment.name
                          : 'N/A'
                    }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="sale-id font-medium truncate">
                    {{
                      Number(tr.amount).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}
                  </p>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <feather-icon
                    title="Generate receipt"
                    icon="FileTextIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="getReceipt(tr._id)"
                  />
                  <feather-icon
                    v-if="tr.credit == true && this.$acl.check('admin')"
                    title="Complete Sale"
                    icon="CheckIcon"
                    style="color: green"
                    class="ml-2"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="completeSale(tr)"
                  />
                  <feather-icon
                    v-if="tr.void !== true && this.$acl.check('admin')"
                    title="Void Sale"
                    icon="XIcon"
                    style="color: red"
                    class="ml-2"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="voidSale(tr)"
                  />
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
export default {
  components: {
    StatisticsCardLine,
  },
  data() {
    return {
      customer_not_found: false,
      database: null,

      selected: [],
      itemsPerPage: 10,
      isMounted: false,
    };
  },
  pouch: {
    customerData() {
      return this.$route.params.id
        ? {
            database: 'express',
            selector: { type: 'customer', _id: this.$route.params.id },
            first: true,
          }
        : {};
    },
    saleData() {
      if (this.user && this.user.role == 'admin') {
        return this.$route.params.id
          ? {
              database: 'express',
              selector: {
                type: 'sale',
                credit: false || undefined,
                void: false,
                // customer: {
                //   _id: "343c9ad5-c42d-4f89-95fa-fc639a170f51",
                // },
              },
              sort: [
                {
                  date_updated: 'desc',
                },
              ],
            }
          : [];
      }
      return [];
    },
    mySaleData() {
      if (this.user && this.user.role == 'cashier') {
        return this.$route.params.id
          ? {
              database: 'express',
              selector: {
                type: 'sale',
                credit: false || undefined,
                void: false,
                cashier: this.user._id,
                // customer: {
                //   _id: this.$route.params.id,
                // },
              },
              sort: [
                {
                  date_updated: 'desc',
                },
              ],
            }
          : [];
      }
      return [];
    },

    creditSaleData() {
      return this.$route.params.id
        ? {
            database: 'express',
            selector: {
              type: 'sale',
              credit: true,
              void: false,
              // customer: {
              //   _id: this.$route.params.id,
              // },
            },
            sort: [
              {
                date_updated: 'desc',
              },
            ],
          }
        : [];
    },
  },
  computed: {
    user() {
      return this.$session.get('user');
    },
    currentPage() {
      if (this.isMounted && 'table' in this.$refs) {
        return this.$refs.table.currentx || 0;
      }
      return 0;
    },
    data() {
      if (!this.customerData) {
        this.customer_not_found = true;
        return null;
      }
      this.customer_not_found = false;
      return this.customerData;
    },
    sales() {
      let data = [];

      if (this.user.role == 'admin') {
        if (this.saleData) {
          data = this.saleData.filter((e) =>
            e.customer ? e.customer._id == this.$route.params.id : false,
          );
        }
      } else {
        if (this.mySaleData) {
          data = this.mySaleData.filter((e) =>
            e.customer ? e.customer._id == this.$route.params.id : false,
          );
        }
      }

      return data;
    },
    creditSales() {
      let data = [];

      if (this.creditSaleData) {
        data = this.creditSaleData.filter((e) =>
          e.customer ? e.customer._id == this.$route.params.id : false,
        );
      }

      return data;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.sales.length;
    },
    totalSales() {
      let totalSales = 0;
      this.sales.forEach((s) => {
        if (!s.credit) {
          totalSales += s.amount;
        }
      });

      return totalSales;
    },
    outstandingBalance() {
      let outstandingBalance = 0;

      this.creditSales.forEach((cs) => {
        outstandingBalance += cs.amount;
      });

      return outstandingBalance;
    },
  },
  methods: {
    parseDate(date) {
      return this.$moment(date).format('ddd DD MMM YYYY, h:mm:ss a');
    },
    addNewData() {
      this.$router
        .push(`/store/sales/new/customer/${this.$route.params.id}`)
        .catch(() => {});
    },
    getReceipt(id) {
      this.$router.push(`/store/sales/${id}/receipt`).catch(() => {});
    },
    voidSale(sale) {
      this.$store.dispatch('sales/voidSale', sale);
    },
    completeSale(sale) {
      this.$store.dispatch('sales/completeSale', sale);
    },
    markAsPaid(id) {
      this.$router.push(`/store/sales/${id}/receipt`).catch(() => {});
    },
  },
  mounted() {
    this.isMounted = true;
  },
  async created() {
    // var db = await idb.getRecord("database");
  },
};
</script>

<style lang="scss">
#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  /* #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // } */
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .sale-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
